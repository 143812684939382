<template>
  <div class="attachment-item">
    <span>
      <file-icon width="20" height="20" fill-color="#0D0D10"></file-icon>
    </span>
    <span class="sub-text-s2">
      {{ attachment.name }}
    </span>
    <text-divider margin="0 0" color="#E6EAEF"></text-divider>

    <span class="sub-text-s2 text-gray-second">{{ attachment.size }}</span>

    <delete-icon
      v-if="showDelete"
      class="delete-icon"
      width="20"
      height="20"
      fill-color="#0D0D10"
      @click="actions.onBtnDelete()"
    ></delete-icon>
  </div>
</template>

<script>
import { reactive } from "vue";
import FileIcon from "../../../../components/console/icons/FileIcon";
import TextDivider from "../../../../components/console/dividers/TextDivider";
import DeleteIcon from "../../icons/DeleteIcon";

export default {
  name: "AttachmentItem",
  components: { DeleteIcon, TextDivider, FileIcon },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["delete"],
  setup(props, { emit }) {
    const state = reactive({});

    const actions = {
      onBtnDelete: () => {
        emit("delete");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
