<template>
  <icon-base
    icon-name="file-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1088 2.8898C10.9224 1.7034 8.99887 1.7034 7.81246 2.8898L3.14645 7.55582C2.95118 7.75108 2.95118 8.06767 3.14645 8.26293C3.34171 8.45819 3.65829 8.45819 3.85355 8.26293L8.51957 3.59691C9.31545 2.80103 10.6058 2.80103 11.4017 3.59691C12.1976 4.39279 12.1976 5.68317 11.4017 6.47905L6.73569 11.1451C6.73347 11.1473 6.73127 11.1495 6.7291 11.1518L5.47949 12.4014C5.17918 12.7017 4.69228 12.7017 4.39197 12.4014C4.09166 12.1011 4.09166 11.6142 4.39197 11.3139L7.80175 7.90409C7.99701 7.70883 7.99701 7.39225 7.80175 7.19699C7.60649 7.00173 7.28991 7.00173 7.09465 7.19699L3.68486 10.6068C2.99403 11.2976 2.99403 12.4177 3.68486 13.1085C4.3757 13.7993 5.49576 13.7993 6.18659 13.1085L11.7499 7.54517C11.7521 7.54295 11.7543 7.54072 11.7565 7.53847L12.1088 7.18615C13.2952 5.99975 13.2952 4.07621 12.1088 2.8898Z"
      :fill="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "FileIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#818287",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
