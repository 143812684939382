<template>
  <icon-base
    icon-name="divider-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <rect
      x="3.42773"
      y="7.42871"
      width="2.28571"
      height="1.14286"
      rx="0.571429"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
    <rect
      x="6.85742"
      y="7.42871"
      width="2.28571"
      height="1.14286"
      rx="0.571429"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
    <rect
      x="10.2852"
      y="7.42871"
      width="2.28571"
      height="1.14286"
      rx="0.571429"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "DividerIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
