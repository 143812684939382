<template>
  <icon-base
    icon-name="link-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2515 2.74852C12.2547 1.75179 10.6387 1.75179 9.64198 2.74853L7.60125 4.78926C7.40599 4.98452 7.40599 5.3011 7.60125 5.49637C7.79651 5.69163 8.11309 5.69163 8.30836 5.49637L10.3491 3.45563C10.9553 2.84942 11.9382 2.84942 12.5444 3.45563C13.1506 4.06184 13.1506 5.04469 12.5444 5.6509L9.85966 8.3356C9.25345 8.94181 8.2706 8.94181 7.66439 8.3356C7.46913 8.14034 7.15255 8.14034 6.95728 8.3356C6.76202 8.53086 6.76202 8.84745 6.95728 9.04271C7.95402 10.0394 9.57004 10.0394 10.5668 9.04271L13.2515 6.35801C14.2482 5.36128 14.2482 3.74526 13.2515 2.74852ZM2.74853 13.2513C3.74526 14.2481 5.36128 14.248 6.35801 13.2513L8.39874 11.2106C8.594 11.0153 8.594 10.6987 8.39874 10.5035C8.20348 10.3082 7.8869 10.3082 7.69164 10.5035L5.6509 12.5442C5.04469 13.1504 4.06184 13.1504 3.45563 12.5442C2.84942 11.938 2.84942 10.9551 3.45563 10.3489L6.14033 7.66424C6.74654 7.05804 7.72939 7.05804 8.3356 7.66424C8.53086 7.85951 8.84744 7.85951 9.04271 7.66424C9.23797 7.46898 9.23797 7.1524 9.04271 6.95714C8.04598 5.96041 6.42996 5.96041 5.43322 6.95714L2.74853 9.64184C1.75179 10.6386 1.75179 12.2546 2.74853 13.2513Z"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "LinkIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
