<template>
  <icon-base
    icon-name="delete-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.77485 3C7.12921 3 6.556 3.41315 6.35183 4.02566L6.19371 4.5H9.80629L9.64817 4.02566C9.444 3.41314 8.87079 3 8.22515 3H7.77485ZM10.8604 4.5L10.5969 3.70943C10.2566 2.68858 9.30123 2 8.22515 2H7.77485C6.69878 2 5.74343 2.68858 5.40314 3.70943L5.13962 4.5H4.50778H3.5H2.5C2.22386 4.5 2 4.72386 2 5C2 5.27614 2.22386 5.5 2.5 5.5H3.625L4.46076 12.1861C4.55459 12.9367 5.19269 13.5 5.94917 13.5H10.0508C10.8073 13.5 11.4454 12.9367 11.5392 12.1861L12.375 5.5H13.5C13.7761 5.5 14 5.27614 14 5C14 4.72386 13.7761 4.5 13.5 4.5H12.5H11.4922H10.8604ZM11.3672 5.5H10.5115C10.5038 5.50018 10.496 5.50018 10.4883 5.5H5.51167C5.50396 5.50018 5.49622 5.50018 5.48847 5.5H4.63278L5.45303 12.062C5.48431 12.3122 5.69701 12.5 5.94917 12.5H10.0508C10.303 12.5 10.5157 12.3122 10.547 12.062L11.3672 5.5Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>

    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.77485 3C7.12921 3 6.556 3.41315 6.35183 4.02566L6.19371 4.5H9.80629L9.64817 4.02566C9.444 3.41314 8.87079 3 8.22515 3H7.77485ZM10.8604 4.5L10.5969 3.70943C10.2566 2.68858 9.30123 2 8.22515 2H7.77485C6.69878 2 5.74343 2.68858 5.40314 3.70943L5.13962 4.5H3.5H2.5C2.22386 4.5 2 4.72386 2 5C2 5.27614 2.22386 5.5 2.5 5.5H3.625L4.46076 12.1861C4.55459 12.9367 5.19269 13.5 5.94917 13.5H10.0508C10.8073 13.5 11.4454 12.9367 11.5392 12.1861L12.375 5.5H13.5C13.7761 5.5 14 5.27614 14 5C14 4.72386 13.7761 4.5 13.5 4.5H12.5H10.8604Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";

export default {
  name: "DeleteIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
