<template>
  <div class="select-box">
    <label v-if="label" class="label">
      <span class="sub-text-s2">{{ label }}</span>
      <span v-if="required" class="text-purple-50">*</span>
    </label>
    <button
      class="select-btn"
      :class="{ 'gray-bg': bgColor === 'gray' }"
      :disabled="disabled"
      @click="actions.toggleSelectList()"
    >
      <span
        v-if="!!selectedItem"
        class="sub-text-s2"
        v-html="selectedItem.text"
      ></span>
      <span v-if="!selectedItem" v-html="selectedItem.text"></span>
      <arrow-icon direction="down" stroke-size="normal"></arrow-icon>
    </button>
    <div ref="selectListElement" class="select-list">
      <ul class="select-list-wrapper">
        <li
          v-for="item in list"
          :key="`selectable-item-${item.value}`"
          @click="actions.selectItem(item)"
        >
          <p class="sub-text-s2" v-html="item.text"></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import ArrowIcon from "../icons/ArrowIcon";

export default {
  name: "InputSelect",
  components: { ArrowIcon },
  props: {
    selectedItem: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "white",
      validator(value) {
        return ["gray", "white"].includes(value);
      },
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["select"],
  setup(props, { emit }) {
    const selectListElement = ref();
    const state = reactive({
      showList: false,
    });

    const actions = {
      toggleSelectList: () => {
        if (!state.showList) {
          selectListElement.value.style.maxHeight = `${
            selectListElement.value.scrollHeight + 2
          }px`;
          state.showList = true;
        } else {
          selectListElement.value.style.maxHeight = "0px";
          state.showList = false;
        }
      },
      selectItem: (item) => {
        emit("select", item);
        selectListElement.value.style.maxHeight = "0px";
        state.showList = false;
      },
    };

    return { state, actions, selectListElement };
  },
};
</script>

<style scoped>
.label {
  display: block;
  margin-bottom: 4px;
}

.select-box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.select-box label {
  margin-bottom: 8px;
}

.select-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 14px 16px;
}
.select-btn.error {
  border: 1px solid #ff4646;
}

.select-btn.gray-bg {
  background: #f6f8fa;
  border: none;
}

.select-list {
  position: absolute;
  width: 100%;
  z-index: 50;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.select-list-wrapper {
  margin-top: 8px;
  border: 1px solid #e6eaef;
  border-radius: 4px;
  list-style: none;
  padding-left: 0;
}
.select-list-wrapper li {
  cursor: pointer;
  padding: 11px 15px;
  background-color: #ffffff;
}

.select-list-wrapper li:hover {
  background-color: #f6f8fa;
}

.select-list-wrapper li p {
  margin-bottom: 0;
}
</style>
