<template>
  <base-modal
    :title="warningTitle"
    :sub-title="warningText"
    :is-close-btn="isCloseBtn"
    position="center"
    :is-header-section="false"
    :is-footer-section="false"
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <div class="btn-wrapper">
        <button-basic
          v-if="isCancelBtn"
          class="cancel-btn"
          :class="{ 'w-100': !isConfirmBtn }"
          :text="cancelText"
          color="#0D0D10"
          bg-color="#ECF1F4"
          text-size="s1"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          v-if="isConfirmBtn"
          class="confirm-btn"
          :class="{ 'w-100': !isCancelBtn }"
          :text="confirmText"
          text-size="s1"
          @action="actions.confirm()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { reactive } from "vue";
import BaseModal from "../../common/BaseModal.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "WarningModal",
  components: { ButtonBasic, BaseModal },
  props: {
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: "취소",
    },
    warningTitle: {
      type: String,
      default: "",
    },
    warningText: {
      type: String,
      default: "",
    },
    confirmText: {
      type: String,
      required: true,
    },
    isCloseBtn: {
      type: Boolean,
      default: true,
    },
    isCancelBtn: {
      type: Boolean,
      default: true,
    },
    isConfirmBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["confirm", "hideModal"],
  setup(props, { emit }) {
    const state = reactive({});

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      confirm: () => {
        emit("confirm");
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
.btn-wrapper {
  display: flex;
  gap: 8px;
  margin-top: 32px;
}

@media (min-width: 768px) {
  .cancel-btn {
    width: 30%;
  }
  .confirm-btn {
    width: 70%;
  }
}

.w-100 {
  width: 100%;
}
</style>
