<template>
  <icon-base
    icon-name="check-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4582 3.97104C13.7504 4.22421 13.782 4.66629 13.5288 4.95846L7.46326 11.9585C7.33031 12.1119 7.13728 12.2001 6.93424 12.2001C6.73121 12.2001 6.53818 12.1119 6.40522 11.9585L2.47079 7.41792C2.21762 7.12575 2.24924 6.68367 2.54141 6.4305C2.83358 6.17733 3.27567 6.20894 3.52884 6.50112L6.93424 10.4311L12.4708 4.04166C12.724 3.74948 13.166 3.71787 13.4582 3.97104Z"
      :fill="fillColor"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
export default {
  name: "CheckIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
