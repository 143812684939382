<template>
  <div class="page-container-new article-form">
    <page-loading v-if="state.pageLoading"></page-loading>

    <div v-if="!state.pageLoading">
      <page-header
        :title="state.pageTitle"
        :back-button="true"
        :is-back-btn-action="true"
        @backBtnAction="actions.openOutModal()"
      ></page-header>

      <div class="container-body">
        <div class="article-form1">
          <input-select
            bg-color="gray"
            :list="state.communitySelectList"
            :selected-item="state.selectedCommunity"
            :disabled="state.paramsClubResourceId"
            @select="(item) => actions.selectCommunity(item)"
          ></input-select>

          <div class="check-list">
            <input-radio
              v-model="state.articleForm.isNotice"
              name="publicType"
              label="일반"
              :value="false"
              for-value="publicTypeBasic"
            ></input-radio>
            <input-radio
              v-model="state.articleForm.isNotice"
              name="publicType"
              label="공지"
              :value="true"
              for-value="publicTypeNotice"
            ></input-radio>
            <input-checkbox
              class="check-box"
              label="필독 게시글 등록"
              :default-value="state.articleForm.isPinned"
              @updateData="(checked) => actions.setIsPinned(checked)"
            ></input-checkbox>
          </div>
        </div>

        <div class="article-form2">
          <input
            class="title-input"
            :class="{ error: !!state.titleError }"
            type="text"
            placeholder="제목을 입력해주세요"
            :value="state.articleForm.title"
            @input="actions.setArticleFormTitle($event.target.value)"
          />

          <article-editor
            placeholder="내용을 입력해주세요"
            :content="state.articleForm.content"
            :attachments="state.articleForm.attachments"
            @updateContent="(obj) => actions.updateArticleFormContent(obj)"
            @addNewAttachment="
              (resourceId) => actions.addNewAttachment(resourceId)
            "
            @popNewAttachment="
              (resourceId) => actions.popNewAttachment(resourceId)
            "
          ></article-editor>

          <hr class="hr-third" />

          <div class="footer">
            <p class="sub-text-s3 text-gray-second">
              건전한 커뮤니티 문화를 위해<br />
              욕설 등 부적절한 내용의 게시물은 제재대상이 될 수 있습니다.
            </p>
            <div class="btn-wrapper">
              <button
                class="cancel sub-title-s1"
                @click="actions.openOutModal()"
              >
                취소
              </button>
              <button
                class="save sub-title-s1"
                :disabled="state.saveDisabled"
                @click="actions.saveArticle()"
              >
                게시
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <teleport to="#teleport">
      <warning-modal
        v-if="state.showOutModal"
        warning-title="게시글 작성을 취소할까요?"
        warning-text="지금까지 작성한 내용은 저장되지 않아요"
        confirm-text="나가기"
        cancel-text="계속쓰기"
        @hideModal="actions.closeOutModal()"
        @confirm="actions.outArticleForm()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import swal from "@/helper/swal";
import { useRoute, useRouter } from "vue-router";
import { computed, onBeforeMount, reactive } from "vue";
import PageHeader from "../../../components/console/headers/PageHeader";
import InputSelect from "../../../components/console/inputs/InputSelect";
import InputRadio from "../../../components/console/inputs/InputRadio";
import InputCheckbox from "../../../components/console/inputs/InputCheckbox";
import ArticleEditor from "./ArticleEditor/ArticleEditor";
import WarningModal from "../../../components/console/modals/WarningModal";
import PageLoading from "../../../components/console/loadings/PageLoading";
import ApiService from "@/api";

export default {
  name: "ArticleForm",
  components: {
    PageLoading,
    WarningModal,
    ArticleEditor,
    InputCheckbox,
    InputRadio,
    InputSelect,
    PageHeader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const route = useRoute();

    const state = reactive({
      pageLoading: true,
      isEdit: computed(() => {
        return !!route.params.articleResourceId;
      }),
      pageTitle: computed(() => {
        return state.isEdit ? "글수정" : "글쓰기";
      }),
      communitySelectList: computed(() => {
        let communityList = store.getters["communities/communities"].map(
          (item) => {
            return { text: item.title, value: item.resourceId };
          }
        );
        return communityList;
      }),
      selectedCommunity: { text: "게시판을 선택해 주세요.", value: null },
      articleForm: {
        title: "",
        content: "",
        communityResourceId: null,
        isNotice: false,
        isPinned: false,
        attachments: [],
      },
      saveDisabled: computed(() => {
        return !state.articleForm.title || !state.articleForm.content;
      }),
      titleError: "",
      showOutModal: false,
      newAttachmentResourceIds: [],
      paramsClubResourceId: computed(() => {
        return route.params.clubResourceId;
      }),
      entClubCommunity: {},
    });

    onBeforeMount(async () => {
      if (store.getters["communities/communities"].length === 0) {
        await store.dispatch("communities/getCommunities");
      }

      if (!state.paramsClubResourceId) {
        if (state.isEdit) {
          await store.dispatch("articles/getArticle", {
            articleResourceId: route.params.articleResourceId,
          });
          let article = store.getters["articles/article"];

          let defaultCommunity = state.communitySelectList.find((item) => {
            return item.value === article.community.resourceId;
          });

          state.selectedCommunity = {
            text: defaultCommunity.text,
            value: defaultCommunity.value,
          };

          state.articleForm.title = article.title;
          state.articleForm.content = article.content;
          state.articleForm.isNotice = article.isNotice;
          state.articleForm.isPinned = article.isPinned;
          state.articleForm.attachments = article.attachments;
          state.articleForm.communityResourceId = defaultCommunity.value;
        } else {
          let currentCommunity = store.getters["communities/community"];
          if (!currentCommunity.resourceId) {
            state.selectedCommunity = {
              text: "게시판을 선택해 주세요.",
              value: null,
            };
          } else {
            state.selectedCommunity = {
              text: currentCommunity.title,
              value: currentCommunity.resourceId,
            };
            state.articleForm.communityResourceId = currentCommunity.resourceId;
          }
        }
      } else {
        // let

        // let getIndex = (el) => el.clubResourceId == state.paramsClubResourceId;
        let findIndex = store.getters["communities/communities"].findIndex(
          (el) => el.clubResourceId == state.paramsClubResourceId
        );

        let selectedCommunity =
          store.getters["communities/communities"][findIndex];

        state.selectedCommunity = {
          text: selectedCommunity.title,
          value: selectedCommunity.resourceId,
        };

        if (state.isEdit) {
          await store.dispatch("articles/getArticle", {
            articleResourceId: route.params.articleResourceId,
          });
          let article = store.getters["articles/article"];

          let defaultCommunity = state.communitySelectList.find((item) => {
            return item.value === article.community.resourceId;
          });

          state.selectedCommunity = {
            text: defaultCommunity.text,
            value: defaultCommunity.value,
          };

          state.articleForm.title = article.title;
          state.articleForm.content = article.content;
          state.articleForm.isNotice = article.isNotice;
          state.articleForm.isPinned = article.isPinned;
          state.articleForm.attachments = article.attachments;
          state.articleForm.communityResourceId = defaultCommunity.value;
        } else {
          let currentCommunity = store.getters["communities/community"];
          if (!currentCommunity.resourceId) {
            state.selectedCommunity = {
              text: "게시판을 선택해 주세요.",
              value: null,
            };
          } else {
            state.selectedCommunity = {
              text: currentCommunity.title,
              value: currentCommunity.resourceId,
            };
            state.articleForm.communityResourceId = currentCommunity.resourceId;
          }
        }

        state.selectedCommunity = {
          text: selectedCommunity.title,
          value: selectedCommunity.resourceId,
        };
      }

      state.pageLoading = false;
    });

    const actions = {
      selectCommunity: (item) => {
        state.selectedCommunity = item;
        state.articleForm.communityResourceId = item.value;
      },
      setIsPinned: (checked) => {
        state.articleForm.isPinned = checked;
      },
      setArticleFormTitle: (value) => {
        state.articleForm.title = value;
        if (!state.articleForm.title) {
          state.titleError = "제목은 필수입니다.";
        } else {
          state.titleError = "";
        }
      },
      saveArticle: () => {
        if (!state.articleForm.communityResourceId) {
          swal.messageAlert("게시판 설정을 하세요.");
          return;
        }

        if (!state.paramsClubResourceId) {
          if (state.isEdit) {
            store
              .dispatch("articles/putArticle", {
                articleResourceId: route.params.articleResourceId,
                params: state.articleForm,
              })
              .then(() => {
                swal.editCompleteToast();
                router.push({
                  name: "console.articles",
                });
              });
          } else {
            store
              .dispatch("articles/postArticle", state.articleForm)
              .then(() => {
                swal.createCompleteToast();
                router.push({
                  name: "console.articles",
                });
              });
          }
        } else {
          if (state.isEdit) {
            store
              .dispatch("articles/putArticle", {
                articleResourceId: route.params.articleResourceId,
                params: state.articleForm,
              })
              .then(() => {
                swal.editCompleteToast();
                router.push({
                  name: "console.clubs.show",
                  params: {
                    clubResourceId: state.paramsClubResourceId,
                  },
                });
              });
          } else {
            store
              .dispatch("articles/postArticle", state.articleForm)
              .then(() => {
                swal.createCompleteToast();
                router.push({
                  name: "console.clubs.show",
                  params: {
                    clubResourceId: state.paramsClubResourceId,
                  },
                });
              });
          }
        }
      },
      openOutModal: () => {
        state.showOutModal = true;
      },
      closeOutModal: () => {
        state.showOutModal = false;
      },
      outArticleForm: () => {
        if (state.newAttachmentResourceIds.length > 0) {
          state.newAttachmentResourceIds.forEach((resourceId) => {
            ApiService.deleteAttachment(resourceId);
          });
        }
        if (!state.paramsClubResourceId) {
          router.push({ name: "console.articles" });
        } else {
          router.push({
            name: "console.clubs.show",
            params: {
              clubResourceId: state.paramsClubResourceId,
            },
          });
        }
      },
      updateArticleFormContent: (obj) => {
        state.articleForm = { ...state.articleForm, ...obj };
      },
      addNewAttachment: (attachmentResourceId) => {
        state.newAttachmentResourceIds.push(attachmentResourceId);
      },
      popNewAttachment: (attachmentResourceId) => {
        let attachmentIndex = state.newAttachmentResourceIds.findIndex(
          (item) => {
            return item === attachmentResourceId;
          }
        );
        if (attachmentIndex > -1) {
          state.newAttachmentResourceIds.splice(attachmentIndex, 1);
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped src="./style.css"></style>
