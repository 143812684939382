<template>
  <icon-base
    icon-name="upload-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49922 6.94922C2.94105 6.94922 3.29922 7.30739 3.29922 7.74922V12.3991C3.33429 12.4215 3.40206 12.4492 3.49922 12.4492H12.4992C12.5964 12.4492 12.6642 12.4215 12.6992 12.3991V7.74922C12.6992 7.30739 13.0574 6.94922 13.4992 6.94922C13.941 6.94922 14.2992 7.30739 14.2992 7.74922V12.4635C14.2992 13.5083 13.3029 14.0492 12.4992 14.0492H3.49922C2.69558 14.0492 1.69922 13.5083 1.69922 12.4635V7.74922C1.69922 7.30739 2.05739 6.94922 2.49922 6.94922Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.43353 1.23431C7.74595 0.921895 8.25248 0.921895 8.5649 1.23431L10.5649 3.23431C10.8773 3.54673 10.8773 4.05327 10.5649 4.36569C10.2525 4.6781 9.74595 4.6781 9.43353 4.36569L7.99922 2.93137L6.5649 4.36569C6.25248 4.6781 5.74595 4.6781 5.43353 4.36569C5.12111 4.05327 5.12111 3.54673 5.43353 3.23431L7.43353 1.23431Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.04766 9.80078C8.48948 9.80078 8.84766 9.44261 8.84766 9.00078L8.84766 2.60078C8.84766 2.15895 8.48948 1.80078 8.04766 1.80078C7.60583 1.80078 7.24766 2.15895 7.24766 2.60078L7.24766 9.00078C7.24766 9.44261 7.60583 9.80078 8.04766 9.80078Z"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
export default {
  name: "UploadIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
