<template>
  <div class="article-editor">
    <div ref="toolbar">
      <button class="ql-header" value="2" type="button">H2</button>
      <button class="ql-header" value="3" type="button">B1</button>
      <button class="ql-header" value="4" type="button">B2</button>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button class="ql-bold"></button>
      <button class="ql-underline">underline</button>
      <button class="ql-strike"></button>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button class="ql-align" value=""></button>
      <button class="ql-align" value="center"></button>
      <button class="ql-align" value="right"></button>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button type="button" class="ql-list" value="bullet"></button>
      <button type="button" class="ql-list" value="ordered"></button>
      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>
      <button type="button" class="ql-image"></button>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <tooltip
        container=".article-editor"
        :distance="20"
        :is-show="state.showVideoTooltip"
        :triggers="['click']"
        @hideEvent="actions.videoButtonNoneActive()"
      >
        <template #button>
          <button
            class="video-btn"
            :class="{ active: state.showVideoTooltip }"
            @click="actions.toggleVideoBtn()"
          >
            <play-icon></play-icon>
          </button>
        </template>
        <template #tooltip>
          <div class="link-form">
            <input
              ref="videoInput"
              v-model="state.videoUrl"
              class="sub-text-s3"
              type="url"
              placeholder="동영상 URL을 입력하세요"
              @keypress.enter="actions.embedVideo()"
            />
            <text-divider color="#E6EAEF"></text-divider>
            <check-icon
              class="check-icon"
              @click="actions.embedVideo()"
            ></check-icon>
          </div>
        </template>
      </tooltip>
      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <tooltip
        container=".article-editor"
        :distance="20"
        :is-show="state.showLinkTooltip"
        :triggers="['click']"
        @hideEvent="actions.linkButtonNoneActive()"
      >
        <template #button>
          <button
            class="link-btn"
            :class="{ active: state.showLinkTooltip }"
            @click="actions.toggleLinkBtn()"
          >
            <link-icon></link-icon>
          </button>
        </template>
        <template #tooltip>
          <div class="link-form">
            <input
              ref="linkInput"
              v-model="state.link"
              class="sub-text-s3"
              type="url"
              placeholder="링크할 URL을 입력하세요"
              @keypress.enter="actions.embedLink()"
            />
            <text-divider color="#E6EAEF"></text-divider>
            <check-icon
              class="check-icon"
              @click="actions.embedLink()"
            ></check-icon>
          </div>
        </template>
      </tooltip>

      <text-divider height="14px" color="#E6EAEF" margin="0 0"></text-divider>

      <button @click="actions.insertHrTag()">
        <divider-icon></divider-icon>
      </button>
    </div>

    <div class="editor-wrapper">
      <div
        ref="quillEditor"
        class="article-editor-content"
        :style="state.quillStyleObj"
      ></div>

      <button
        class="upload-btn sub-title-s1"
        :disabled="state.fileUploading"
        :class="{ 'text-gray-third': state.fileUploading }"
        @click="actions.openFileSelector()"
      >
        <template v-if="!state.fileUploading">
          파일 업로드 <upload-icon fill-color="#0D0D10"></upload-icon>
        </template>
        <template v-if="state.fileUploading">
          업로드 중 <button-loading></button-loading>
        </template>
      </button>

      <input
        ref="fileInput"
        type="file"
        hidden
        @change="actions.addAttachment($event)"
      />

      <div v-if="state.attachments.length > 0" class="attachments">
        <ul>
          <li
            v-for="attachment in state.attachments"
            :key="`attachment-${attachment.resourceId}`"
          >
            <attachment-item
              :attachment="attachment"
              :show-delete="true"
              @delete="actions.deleteAttachment(attachment)"
            ></attachment-item>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import Delta from "quill-delta";

import "quill/dist/quill.bubble.css";
import {
  onMounted,
  reactive,
  ref,
  onBeforeUnmount,
  computed,
  watch,
} from "vue";
import ApiService from "@/api";
import TextDivider from "../../../../components/console/dividers/TextDivider";
import UploadIcon from "../../../../components/console/icons/UploadIcon";
import AttachmentItem from "../../../../components/console/items/AttachmentItem/AttachmentItem";
import ButtonLoading from "../../../../components/console/loadings/ButtonLoading";
import Tooltip from "../../../../components/console/tooltip/Tooltip";
import PlayIcon from "../../../../components/console/icons/PlayIcon";
import CheckIcon from "../../../../components/console/icons/CheckIcon";
import swal from "@/helper/swal";
import DividerIcon from "../../../../components/console/icons/DividerIcon";
import LinkIcon from "../../../../components/console/icons/LinkIcon";
import FileStackService from "@/services/FileStackService";

export default {
  name: "ArticleEditor",
  components: {
    LinkIcon,
    DividerIcon,
    CheckIcon,
    PlayIcon,
    Tooltip,
    ButtonLoading,
    AttachmentItem,
    UploadIcon,
    TextDivider,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    attachments: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["updateContent"],
  setup(props, { emit }) {
    const quillEditor = ref();
    const toolbar = ref();
    const fileInput = ref();
    const videoInput = ref();
    const linkInput = ref();

    const state = reactive({
      quillStyleObj: {
        width: "100%",
        height: "auto",
        minHeight: "240px",
        paddingBottom: "60px",
      },
      form: {
        content: props.content ? props.content : "",
        attachments: computed(() => {
          return state.attachments.reduce((result, currentArray) => {
            result.push(currentArray.resourceId);
            return result;
          }, []);
        }),
      },
      lastSelection: { index: 0, range: 0 },
      attachments: [],
      fileUploading: false,
      videoUrl: "",
      link: "",
      showVideoTooltip: false,
      showLinkTooltip: false,
    });

    let quillInstance;

    onMounted(async () => {
      // 아이콘 커스텀
      let icons = Quill.import("ui/icons");
      icons["header"]["2"] = `<img src="/assets/images/svg/ic_header2.svg" />`;
      icons["header"]["3"] = `<img src="/assets/images/svg/ic_header3.svg" />`;
      icons["header"]["4"] = `<img src="/assets/images/svg/ic_header4.svg" />`;
      icons["bold"] = `<img src="/assets/images/svg/ic_bold.svg"/>`;
      icons["underline"] = `<img src="/assets/images/svg/ic_underline.svg" />`;
      icons["strike"] = `<img src="/assets/images/svg/ic_strike.svg" />`;
      icons["align"][""] = `<img src="/assets/images/svg/ic_align_left.svg" />`;
      icons["align"][
        "center"
      ] = `<img src="/assets/images/svg/ic_align_center.svg" />`;
      icons["align"][
        "right"
      ] = `<img src="/assets/images/svg/ic_align_right.svg" />`;
      icons["list"][
        "bullet"
      ] = `<img src="/assets/images/svg/ic_list_bullet.svg" />`;
      icons["list"][
        "ordered"
      ] = `<img src="/assets/images/svg/ic_list_ordered.svg" />`;
      icons[
        "image"
      ] = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path class="ql-fill" fill-rule="evenodd" clip-rule="evenodd" d="M13 3H3L3 9.04273L5.60532 5.69303C5.701 5.57002 5.84856 5.49865 6.00439 5.50002C6.16022 5.50139 6.3065 5.57533 6.4 5.7L9.0541 9.2388L10.6464 7.64645C10.8417 7.45118 11.1583 7.45118 11.3536 7.64645L13 9.29289V3ZM3 12.5V10.6716L5.99277 6.8237L8.6 10.3C8.68697 10.416 8.81997 10.4885 8.96456 10.4987C9.10914 10.509 9.25106 10.456 9.35355 10.3536L11 8.70711L13 10.7071V12.5C13 12.7761 12.7761 13 12.5 13H3.5C3.22386 13 3 12.7761 3 12.5ZM2 3V10.5V12.5V13C2 13.5523 2.44772 14 3 14H3.5H12.5H13C13.5523 14 14 13.5523 14 13V12.5V10.5V3C14 2.44772 13.5523 2 13 2H3C2.44772 2 2 2.44772 2 3ZM11 6C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6Z" fill="#0D0D10"/>
</svg>`;

      let EmbedBlock = Quill.import("blots/block/embed");
      class Hr extends EmbedBlock {}
      Hr.blotName = "hr"; //now you can use .ql-hr classname in your toolbar
      Hr.tagName = "hr";
      Quill.register({
        "formats/hr": Hr,
      });

      // 붙여넣기 할때, 맨 위로 튀는 현상 fix
      // const Clipboard = Quill.import("modules/clipboard");

      // note 글자 서식 복사 제한하는 로직
      // class PlainTextClipboard extends Clipboard {
      //   onPaste(e) {
      //     if (e.defaultPrevented || !this.quill.isEnabled()) return;
      //     let range = this.quill.getSelection();
      //     let delta = new Delta().retain(range.index);
      //
      //     if (
      //       e &&
      //       e.clipboardData &&
      //       e.clipboardData.types &&
      //       e.clipboardData.getData
      //     ) {
      //       let text = (e.originalEvent || e).clipboardData.getData(
      //         "text/plain"
      //       );
      //       let cleanedText = this.convert(text);
      //
      //       // Stop the data from actually being pasted
      //       e.stopPropagation();
      //       e.preventDefault();
      //
      //       // Process cleaned text
      //       delta = delta.concat(cleanedText).delete(range.length);
      //       this.quill.updateContents(delta, Quill.sources.USER);
      //       // range.length contributes to delta.length()
      //       this.quill.setSelection(
      //         delta.length() - range.length,
      //         Quill.sources.SILENT
      //       );
      //
      //       return false;
      //     }
      //   }
      // }

      let BlockEmbed = Quill.import("blots/block/embed");

      class ImageBlot extends BlockEmbed {
        static create(value) {
          let node = super.create();
          node.setAttribute("alt", value.alt);
          node.setAttribute("src", value.url);
          return node;
        }

        static value(node) {
          // 이미지 넣을 때 사이즈 제한하는 로직.
          // node.style.width = "50%";
          return {
            alt: node.getAttribute("alt"),
            url: node.getAttribute("src"),
          };
        }
      }
      ImageBlot.blotName = "image";
      ImageBlot.tagName = "img";

      Quill.register(ImageBlot);

      let options = {
        compatibilityMode: false,
        modules: {
          toolbar: {
            container: toolbar.value,
            handlers: {
              image: function () {
                let range = quillInstance.getSelection();
                if (range) {
                  if (range.length == 0) {
                    console.log("User cursor is at index", range.index);
                  } else {
                    let text = quillInstance.getText(range.index, range.length);
                    console.log("User has highlighted: ", text);
                  }
                } else {
                  console.log("User cursor is not in editor");
                  return;
                }

                const fileStack = new FileStackService();
                fileStack.options.transformations.crop = true;

                fileStack.options.onFileUploadFinished = (fileMetaData) => {
                  const formData = new FormData();
                  formData.append("file", fileMetaData.url);

                  ApiService.postAttachment(formData).then((res) => {
                    if (res.data.success) {
                      state.attachments.push(res.data.data);

                      quillInstance.insertText(range.index, "\n");

                      quillInstance.insertEmbed(
                        range.index + 1,
                        "image",
                        {
                          url: res.data.data.url, // any url
                          alt: res.data.data.url,
                        },
                        "user"
                      );

                      quillInstance.setSelection(range.index + 2);
                    }
                  });
                };
                fileStack.open(fileStack.options);
              },
            },
          },
        },
        debug: false, // 'error', 'warn', 'log', or 'info' , false
        placeholder: props.placeholder,
        theme: "snow", // bubble or snow
        bounds: quillEditor.value,
      };

      quillInstance = await new Quill(quillEditor.value, options);

      // note 글자 서식 복사 제한하는 로직
      quillInstance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = [];
        delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === "string") {
            ops.push({
              insert: op.insert,
            });
          }
        });
        delta.ops = ops;
        return delta;
      });

      if (props.content) {
        let content = props.content;
        console.log(htmlToDelta(props.content));
        let convertedDelta = htmlToDelta(content);
        console.log(convertedDelta);
        quillInstance.setContents(convertedDelta, "api");
      }

      quillInstance.on("text-change", update);
      quillInstance.on("selection-change", setLastSelection);

      quillEditor.value.addEventListener("click", () => {
        if (!quillInstance.hasFocus()) {
          const contentLength = quillInstance.getLength();
          quillInstance.setSelection(contentLength + 1, Quill.sources.USER);
        }
      });

      if (props.attachments.length > 0) {
        state.attachments = props.attachments;
      }
    });

    onBeforeUnmount(() => {
      quillInstance.off("text-change", update);
      quillInstance.off("selection-change", setLastSelection);
    });

    watch(
      () => state.form,
      (updatedForm) => {
        emit("updateContent", updatedForm);
      },
      { deep: true }
    );

    const update = (delta) => {
      if (delta) {
        if (quillInstance.getLength() > 1) {
          state.form.content = quillInstance.root.innerHTML;
        } else {
          state.form.content = "";
        }
      }
    };

    const setLastSelection = (range, oldRange) => {
      if (range) {
        state.lastSelection = range;
      } else {
        state.lastSelection = oldRange;
      }
    };

    const htmlToDelta = (htmlString) => {
      const div = document.createElement("div");
      div.setAttribute("id", "htmlToDelta");
      div.innerHTML = `<div id="quillEditor" style="display:none">${htmlString}</div>`;
      document.body.appendChild(div);
      const quill = new Quill("#quillEditor", {
        theme: "snow",
      });
      const delta = quill.getContents();
      document.getElementById("htmlToDelta").remove();
      return delta;
    };

    const getVideoUrl = (url) => {
      let match =
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
        ) ||
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
        ) ||
        url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#]*).*/);

      if (match && match[2].length === 11) {
        return "https" + "://www.youtube.com/embed/" + match[2] + "?showinfo=0";
      }
      if (
        (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))
      ) {
        return (
          (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
        );
      }
      return null;
    };

    const actions = {
      test: () => {
        console.log("test");
      },
      embedVideo: () => {
        let validateVideoUrl = getVideoUrl(state.videoUrl);

        if (validateVideoUrl) {
          quillInstance.insertEmbed(
            state.lastSelection.index,
            "video",
            validateVideoUrl
          );
          state.videoUrl = "";
          state.showVideoTooltip = false;
        } else {
          swal.messageAlert("유효하지 않은 동영상 링크 입니다.");
          state.videoUrl = "";
        }
      },
      embedLink: () => {
        console.log(state.lastSelection);
        console.log(state.link);

        if (state.lastSelection.length > 0) {
          quillInstance.formatText(
            state.lastSelection.index,
            state.lastSelection.length,
            "link",
            state.link
          );
        } else {
          quillInstance.insertText(
            state.lastSelection.index,
            state.link,
            "link",
            state.link
          );
        }
        state.link = "";
        state.showLinkTooltip = false;

        // quillInstance.insertEmbed(state.lastSelection, "link", state.link);
      },
      openFileSelector: () => {
        fileInput.value.click();
      },
      addAttachment: (e) => {
        state.fileUploading = true;
        const files = e.target.files || e.dataTransfer.files;
        const formData = new FormData();
        formData.append("file", files[0]);
        ApiService.postAttachment(formData).then((res) => {
          if (res.data.success) {
            state.attachments.unshift(res.data.data);
            state.fileUploading = false;
            emit("addNewAttachment", res.data.data.resourceId);
          }
        });
      },
      deleteAttachment: (attachment) => {
        ApiService.deleteAttachment(attachment.resourceId).then((res) => {
          if (res.data.success) {
            let deleteIndex = state.attachments.findIndex((item) => {
              return item.resourceId === attachment.resourceId;
            });
            state.attachments.splice(deleteIndex, 1);

            let originAttachment = props.attachments.find((item) => {
              return item.resourceId === attachment.resourceId;
            });
            if (!originAttachment) {
              emit("popNewAttachment", attachment.resourceId);
            }
          } else {
            console.error("attachment delete error");
          }
        });
      },
      toggleVideoBtn: () => {
        state.showVideoTooltip = !state.showVideoTooltip;
        if (!state.showVideoTooltip) {
          state.videoUrl = "";
        } else {
          videoInput.value.focus();
        }
      },
      videoButtonNoneActive: () => {
        state.showVideoTooltip = false;
        state.videoUrl = "";
      },
      insertHrTag: () => {
        let range = quillInstance.getSelection();

        if (!range) {
          const contentLength = quillInstance.getLength();
          quillInstance.setSelection(contentLength + 1, Quill.sources.USER);
          range = quillInstance.getSelection();
        }

        quillInstance.insertText(range.index, "\n");
        quillInstance.insertEmbed(range.index + 1, "hr", "null");
        quillInstance.setSelection(range.index + 2, Quill.sources.SILENT);
      },
      toggleLinkBtn: () => {
        state.showLinkTooltip = !state.showLinkTooltip;
        if (!state.showLinkTooltip) {
          state.link = "";
        } else {
          setTimeout(() => {
            linkInput.value.focus();
          }, 50);
        }
      },
      linkButtonNoneActive: () => {
        state.link = "";
        state.showLinkTooltip = false;
      },
    };

    return {
      toolbar,
      quillEditor,
      fileInput,
      videoInput,
      linkInput,
      state,
      actions,
    };
  },
};
</script>

<style scoped src="./style.css"></style>
