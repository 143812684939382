<template>
  <div>
    <label class="radio-label" :for="forValue" :class="{ disabled: disabled }">
      <input
        :id="forValue"
        type="radio"
        :name="name"
        :value="value"
        :checked="value === modelValue"
        :disabled="disabled"
        @change="actions.updateValue($event)"
      />
      <span
        class="sub-text-s2"
        :class="{ 'text-gray-second': value !== modelValue }"
        >{{ label }}</span
      >
    </label>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "InputRadio",
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    forValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = reactive();

    const actions = {
      updateValue: (e) => {
        emit("update:modelValue", e.target.value);
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 1px solid #e6eaef;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

input[type="radio"]:checked {
  background: #ffffff;
  border: 1px solid #8e1eff;
  padding: 2px;
  position: relative;
}

input[type="radio"]:checked::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #8e1eff;
}

.radio-label span {
  margin-left: 6px;
}

label.disabled {
  cursor: auto;
}

.disabled input {
  cursor: none;
}

.radio-label input:disabled {
  background: #ecf1f4;
}
</style>
